import React, { useEffect, useState } from "react";
import Style from "./BaseLayout.module.scss";
import Home from "../pages/Home";
import { Route, Routes } from "react-router-dom";
import { Box, Grid } from "@mui/material";


export default function BaseLayout() {
  return (
    <Box className={Style.light}>
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        minHeight={"100vh"}
        justifyContent={"space-between"}
      >
        <Grid item flexGrow={1}>
          <Routes>
            <Route exact path={"/"} element={<Home />} />
          </Routes>
        </Grid>
      </Grid>
    </Box>
  );
};
