import React, { useState, useEffect } from "react";
import Terminal from "../components/terminal/Terminal";
import SocialIcon from "../components/socialIcon/SocialIcon";
import { Box, Container, Divider, Typography, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { info } from "../info/Info";

export default function Home() {
  const [expanded, setExpanded] = useState([]);
  const [expanded2, setExpanded2] = useState([]);
  useEffect(() => {
    if (window.innerWidth < 900) {
      setExpanded(["1"]);
    } else {
      setExpanded(["1"]);
      setExpanded2(["4"]);
    }
  }, []);

  const nodesLeftIds = ["1", "2", "3"];
  const nodesRightIds = ["4", "5", "6"];
  const nodeIds = nodesLeftIds.concat(nodesRightIds);
  const nodeClickHandler = (event, nodeId, side) => {
    let screenWith = window.innerWidth;
    if (screenWith < 900) {
      if (nodeIds.includes(nodeId)) {
        if (nodesLeftIds.includes(nodeId)) {
          setExpanded([nodeId]);
          setExpanded2([]);
        } else {
          setExpanded2([nodeId]);
          setExpanded([]);
        }
      }
    } else {
      if (nodeIds.includes(nodeId)) {
        if (side === "left") {
          setExpanded([nodeId]);
        } else {
          setExpanded2([nodeId]);
        }
      }
    }
  };

  const nodeLeftSideClickHandler = (event, nodeId) => {
    nodeClickHandler(event, nodeId, "left");
  };

  const nodeRightSideClickHandler = (event, nodeId) => {
    nodeClickHandler(event, nodeId, "right");
  };
  const treeViewMinWidth = "240px";
  const captionTextStyle = {
    color: "rgb(205, 204, 217, 0.6)",
  };
  const skillsTextStyle = {
    color: "rgb(205, 204, 217, 0.9)",
  };
  const skillsHeadTextStyle = {
    color: "rgb(0, 255, 164,0.6)",
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      mt={"3rem"}
    >
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 2 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          style={{
            background: info.gradient,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {info.firstName} {info.lastName}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
          className={"caption-text"}
          style={captionTextStyle}
        >
          Trying to do well and be creative
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
          className={"caption-text"}
          style={captionTextStyle}
        >
          Exploring the missing gear of technology
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
          className={"caption-text"}
          style={captionTextStyle}
        >
          Learning every-part of my interest
        </Typography>
      </Container>
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 2, pb: 4 }}
      >
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)" }}>
          <Box
            display={"flex"}
            gap={"1.5rem"}
            justifyContent={"center"}
            fontSize={{ xs: "2rem", md: "2.5rem" }}
          >
            {info.socials.map((social, index) => (
              <SocialIcon
                key={index}
                link={social.link}
                icon={social.icon}
                label={social.label}
              />
            ))}
          </Box>
        </Box>
      </Container>

      <Terminal>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Box
                key="name-key"
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(1, 1fr)",
                  textAlign: "center",
                }}
              >
                {info.miniBio.map((bio, index) => (
                  <Box sx={{ flexGrow: 1 }}>
                    <Box
                      style={{ cursor: "default", color: "white" }}
                      fontSize={"1.2rem"}
                      fontFamily={"Courier New, Courier, monospace"}
                      borderRadius={"0 0 0.5rem 0.5rem"}
                    >
                      <div style={skillsTextStyle}> {bio.text}</div>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ color: "white", background: "white" }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TreeView
                key={999}
                aria-label="file system navigator"
                expanded={expanded}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={nodeLeftSideClickHandler}
                sx={{ width: treeViewMinWidth }}
              >
                <TreeItem
                  nodeId="1"
                  label="Architecture"
                  fontSize={"1.5rem"}
                  fontFamily={"Courier New, Courier, monospace"}
                  py={{ xs: "1rem", md: "2rem" }}
                  px={{ xs: "2rem", md: "3rem" }}
                  borderRadius={"0 0 0.5rem 0.5rem"}
                  sx={{ backgroundColor: "#27242f" }}
                  style={skillsHeadTextStyle}
                >
                  {info.skills.architecture.map((v, i) => (
                    <TreeItem
                      nodeId={"1" + i}
                      label={v}
                      style={skillsTextStyle}
                    />
                  ))}
                </TreeItem>

                <TreeItem
                  nodeId="2"
                  label="Data"
                  fontSize={"1.5rem"}
                  fontFamily={"Courier New, Courier, monospace"}
                  py={{ xs: "1rem", md: "2rem" }}
                  px={{ xs: "2rem", md: "3rem" }}
                  borderRadius={"0 0 0.5rem 0.5rem"}
                  sx={{ backgroundColor: "#27242f" }}
                  style={skillsHeadTextStyle}
                >
                  {info.skills.t4.map((v, i) => (
                    <TreeItem
                      nodeId={"2" + i}
                      label={v}
                      style={skillsTextStyle}
                    />
                  ))}
                </TreeItem>
                <TreeItem
                  nodeId="3"
                  label="Development"
                  fontSize={"1.5rem"}
                  fontFamily={"Courier New, Courier, monospace"}
                  py={{ xs: "1rem", md: "2rem" }}
                  px={{ xs: "2rem", md: "3rem" }}
                  borderRadius={"0 0 0.5rem 0.5rem"}
                  sx={{ backgroundColor: "#27242f" }}
                  style={skillsHeadTextStyle}
                >
                  {info.skills.t5.map((v, i) => (
                    <TreeItem
                      nodeId={"3" + i}
                      label={v}
                      style={skillsTextStyle}
                    />
                  ))}
                </TreeItem>
              </TreeView>
            </Grid>
            <Grid item xs={12} md={6}>
              {" "}
              <TreeView
                key={99}
                expanded={expanded2}
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={nodeRightSideClickHandler}
                sx={{ width: treeViewMinWidth }}
              >
                <TreeItem
                  nodeId="4"
                  label="JS"
                  fontSize={"1.5rem"}
                  fontFamily={"Courier New, Courier, monospace"}
                  py={{ xs: "1rem", md: "2rem" }}
                  px={{ xs: "2rem", md: "3rem" }}
                  borderRadius={"0 0 0.5rem 0.5rem"}
                  sx={{ backgroundColor: "#27242f" }}
                  style={skillsHeadTextStyle}
                >
                  {info.skills.js.map((v, i) => (
                    <TreeItem
                      nodeId={"4" + i}
                      label={v}
                      style={skillsTextStyle}
                    />
                  ))}
                </TreeItem>
                <TreeItem
                  nodeId="5"
                  label=".Net"
                  fontSize={"1.5rem"}
                  fontFamily={"Courier New, Courier, monospace"}
                  py={{ xs: "1rem", md: "2rem" }}
                  px={{ xs: "2rem", md: "3rem" }}
                  borderRadius={"0 0 0.5rem 0.5rem"}
                  sx={{ backgroundColor: "#27242f" }}
                  style={skillsHeadTextStyle}
                >
                  {info.skills.dotnet.map((v, i) => (
                    <TreeItem
                      nodeId={"5" + i}
                      label={v}
                      style={skillsTextStyle}
                    />
                  ))}
                </TreeItem>
                <TreeItem
                  nodeId="6"
                  label="Minors"
                  fontSize={"1.5rem"}
                  fontFamily={"Courier New, Courier, monospace"}
                  py={{ xs: "1rem", md: "2rem" }}
                  px={{ xs: "2rem", md: "3rem" }}
                  borderRadius={"0 0 0.5rem 0.5rem"}
                  sx={{ backgroundColor: "#27242f" }}
                  style={skillsHeadTextStyle}
                >
                  {info.skills.minors.map((v, i) => (
                    <TreeItem
                      nodeId={"6" + i}
                      label={v}
                      style={skillsTextStyle}
                    />
                  ))}
                </TreeItem>
              </TreeView>
            </Grid>
          </Grid>
        </Box>
      </Terminal>
    </Box>
  );
}
