import React from "react";
import Style from "./Terminal.module.scss";
import classNames from "classnames";
import { Box, Grid } from "@mui/material";

function Terminal(props) {
  const { text = "" } = props;

  return (
    <>
      <Box
        component={"section"}
        className={classNames(Style.terminal, Style.shadowed)}
        borderRadius={"0.5rem"}
        mb={"4rem"}
      >
        <Box
          py={{ xs: "1rem", md: "2rem" }}
          px={{ xs: "2rem", md: "3rem" }}
          borderRadius={"0 0 0.5rem 0.5rem"}
          sx={{ backgroundColor: "#27242f", minHeight: "50vh" }}
          fontSize={"1.5rem"}
          fontFamily={"Courier New, Courier, monospace"}
        >
          {text}
          {props.children}
        </Box>
      </Box>
    </>
  );
}

export default Terminal;
/*
<Box
      component={"section"}
      className={classNames(Style.terminal, Style.shadowed)}
      width={{ xs: "80%", md: "800px" }}
      borderRadius={"0.5rem"}
      mb={"4rem"}
    >
      <Box
        py={{ xs: "1rem", md: "2rem" }}
        px={{ xs: "2rem", md: "3rem" }}
        borderRadius={"0 0 0.5rem 0.5rem"}
        width={{ md: "800px" }}
        sx={{ backgroundColor: "#27242f", minHeight: "50vh" }}
        fontSize={"1.5rem"}
        fontFamily={"Courier New, Courier, monospace"}
      >
        {text}
        {props.children}
      </Box>
    </Box>
*/
