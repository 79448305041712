/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
  firstName: "Oguz",
  lastName: "Ozgen",
  initials: "js", // the example uses first and last, but feel free to use three or more if you like.
  position: "Senior Software Developer",
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    {
      emoji: "💼",
      text: "Senior Software Developer",
    },
  ],
  socials: [
    {
      link: "https://github.com/oguzozgen",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/oguzozgen",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    {
      link: "https://twitter.com/oguzozgenn",
      icon: "fa fa-twitter",
      label: "twitter",
    },
    // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio: "Trying to do well and be creative. Exploring the missing gear of technology. Learning every-part of my interest.",
  skills: {
    architecture: [
      "Microservices",
      "IDP-Keycloak",
      "Gateways",
      "Containerization",
      "Telemetry",
      "QA Automation",
    ],
    js: [
      "JavaScript",
      "ReacTJS",
      "NodeJS",
      "ExpressJS",
      "GIS/Spatial",
      "Visualization",
    ],
    dotnet: [".Net", "C#", "EFCore", "RazorPages/Blazor"],
    t4: ["RESTful/GRPC/Sockets/WebRTC", "NoSQL/MongoDB", "SQL/ORMs", "Redis/RabbitMQ"],
    t5: ["Agile", "Scrum", "Jira", "Azure DevOps", "Figma"],
    minors: [
      "Java - Spring",
      "Azure",
      "Advanced Postman",
      "OpenTelemetry",
      "Flutter",
      "IOT",
    ],
  },
};
